import { IconButton } from "@chakra-ui/button"
import { Box, Flex, Link, SimpleGrid, Text } from "@chakra-ui/layout"
import React from "react"
import Image from "../components/Image/Image"
import Layout from "../components/Layout/Layout"
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai"
import { Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const socialItems = [
  {
    title: "Instagram",
    icon: <AiFillInstagram />,
    color: "purple.500",
    link: "https://www.instagram.com/elisaparron",
  },
  {
    title: "Twitter",
    icon: <AiFillTwitterSquare />,
    color: "blue.300",
    link: "https://twitter.com/elisaparn",
  },
  {
    title: "Facebook",
    icon: <AiFillFacebook />,
    color: "blue.600",
    link: "https://www.facebook.com/ElisaParronPhotography",
  },
]

const ContactPage = () => {
  return (
    <Layout navbarBottom pageTitle="contact">
      <Box maxW="xl" m="0 auto" mt={[0, 0, "md", "md"]}>
        <SimpleGrid columns={[1, 1, 2, 2]} align="center" justify="center">
          <Box w="100%">
            <Box m="0 auto" w={[150, 250, 250, 250]}>
              <Image
                filename="elisaparron.png"
                alt="elisa parron"
                className="contact-img"
              />
            </Box>
          </Box>
          <Box pt={["xs", "xs", "md", "md"]}>
            <Text
              color="whiteAlpha.800"
              fontSize={12}
              fontFamily="Poppins"
              mt="sm"
              textAlign="center"
            >
              <Trans>Demande de collaboration</Trans>
            </Text>
            <Text
              color="whiteAlpha.800"
              fontFamily="Poppins"
              mt="xs"
              fontWeight={600}
              fontSize={15}
              textAlign="center"
            >
              contact@elisaparron.art
            </Text>
            <Text
              color="whiteAlpha.800"
              fontFamily="Poppins"
              mt="md"
              fontSize={12}
              textAlign="center"
            >
              <Trans>Service après-vente</Trans>
            </Text>
            <Text
              color="whiteAlpha.800"
              fontFamily="Poppins"
              mt="xs"
              fontWeight={600}
              fontSize={15}
              textAlign="center"
            >
              sav@elisaparron.art
            </Text>
            <Flex justify="center" align="center" mt={["xs", "xs", 65, 65]}>
              {socialItems.map((e, i) => {
                return (
                  <Link href={e.link} isExternal key={i} mr="xs">
                    <IconButton
                      bgColor="transparent"
                      color={e.color}
                      _hover={{
                        bgColor: "rgba(30,30,30,1)",
                      }}
                      aria-label={e.title}
                      icon={e.icon}
                      _focus={{
                        boxShadow: "none",
                      }}
                      size="md"
                    >
                      Facebook
                    </IconButton>
                  </Link>
                )
              })}
            </Flex>
          </Box>
        </SimpleGrid>
      </Box>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
